@import (reference) "variables";
@import (reference) "mixins";
@import "fonts";

.error-page {
  height: 100%;
  min-height: 100%;
  font-size: 14px;
  color: @color-dark;

  body {
    height: 100%;
  }

  .error-head {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
  }

  .placeholder {
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .code {
    font-size: 100px;
    line-height: 100px;
    font-weight: bold;
    margin-bottom: 40px;
    font-family: @font-bebas;
  }
}